@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.customToolbar {
  width: 100%;
  padding-bottom: 1em;
  position: absolute;
  z-index: 20000000000000;
  top: -41px;
  left: 16px;
}
.HUI .MuiFilledInput-input {
  height: 100%;
}
.MuiContainer-root {
  overflow: hidden;
}
.MuiContainer-maxWidthLg {
  max-width: initial !important;
}

.rdgRoot2 div[role='row'] div[role='gridcell'] {
  white-space: normal;
}

.MuiGrid-root {
  padding-left: 24px;
  padding-right: 24px;
}

.MuiGrid-item {
  padding-left: 0;
  padding-right: 0;
}

.MuiDivider-root {
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
}
footer {
  display: none !important;
}

.rdg.fill-grid {
  block-size: 100%;
}

.rdg {
  border: 0;
  border-radius: 8px;
}

.row-expander-cell {
  background-color: rgba(0, 0, 0, 0.06) !important;
  cursor: pointer;
}

.rdg-cell {
  font-family: 'Open Sans';
  border-right: 0;
}

[role='columnheader'] {
  font-weight: normal;
  color: #3d4043;
  border-top: 0;
}

[role='columnheader'] > div:nth-child(1) {
  transition: all 0.2s ease-in-out;
}

[role='columnheader'] > div:nth-child(1):hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-right: 3px solid rgba(0, 0, 0, 0.1);
}
[tabindex='-1'] {
  border-top: 0;
}

.dgFilter {
  inline-size: 100%;
  padding: 4px;
  font-size: 14px;
}

.dgFilterConainer {
  line-height: 35px;
  padding: 0;
}

.dgFilterConainer > div {
  padding-block: 0;
  padding-inline: 8px;
}

.rdgRoot2 {
  .MuiTypography-body1,
  .MuiSelect-select,
  .HUI .MuiFilledInput-input {
    font-size: 8pt !important;
  }

  [role='columnheader'] {
    font-size: 8pt !important;
  }
}

*:not(.releaseNotes).MuiBox-root {
  overflow-x: auto;
}
.rdgRoot {
  min-width: 1600px;
}

.dgFilterConainer > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}
.rdgRoot {
  display: inline;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
  width: 100%;
}

.rdgRoot2 {
  display: flex;
  flex-direction: column;
  block-size: 100%;
  gap: 10px;
  width: 100%;
}

.rdgRoot > .rdg,
.rdgRoot2 > .rdg {
  flex: 1;
}

// .rdgRoot2 div[role='row'] div[role='gridcell'] {
//   border-right: 1px solid rgba(0, 0, 0, 0.03);
// }

.rdgRoot2 div[role='row'] div[role='gridcell'] {
  overflow: auto;
}

.filter-cell {
  line-height: 35px;
  padding: 0;
  // border-right: 1px solid rgba(0, 0, 0, 0.03);
}

.filter-cell > div {
  padding-block: 0;
  padding-inline: 8px;
}

.filter-cell > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}

.filter-cell > div:nth-child(2) {
  padding: 0.5em;
}

.datagridExpander {
  float: right;
  float: inline-end !important;
  display: table;
  block-size: 100%;
}

.datagridExpander > span {
  display: table-cell;
  vertical-align: middle;
}

.rdgPadding {
}

.react-grid-Header {
  display: none !important;
}

.react-grid-Viewport {
  top: 0px !important;
}

.rdg-child-cell .react-grid-Cell__value {
  line-height: 60px !important;
}

.rdg-cell:not([role*='columnheader']) {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: left;
}

.rdgRoot div[role='row'] div[role='gridcell'] {
  background: white;
  font-weight: bold;
  color: #2d3f6a;
}
#main-navigation .MuiTypography-noWrap {
  white-space: break-spaces !important;
}
#newLoginList .MuiListItem-root {
  padding: 0;
}
*[style*='--rdg-row-height:500px;'] > [role*='gridcell'] {
  background: #f9f9f9 !important;
}

.rdgRoot2 div[role='row'] div[role='gridcell'] {
  background: #ffffff;
  font-weight: normal;
  color: black;
}

.rdgRoot::-webkit-scrollbar {
  width: 14px;
  height: 15px;
}

.rdgRoot::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.rdgRoot::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.rdgRoot::-webkit-scrollbar-corner {
  background-color: transparent;
}

.costObjectFilter {
  margin-left: -30px;
}

div[role='row'] > *[aria-selected='true'] {
  outline: none !important;
}

.completedReview {
  background-color: #89f336 !important;
}
.completedReview .rdg-cell {
  background-color: #89f336 !important;
}

.elevRisk {
  background-color: #ffed29 !important;
}
.elevRisk .rdg-cell {
  background-color: #ffed29 !important;
}

.highRisk {
  background-color: #ffbf00 !important;
}
.highRisk .rdg-cell {
  background-color: #ffbf00 !important;
}

.lowRisk {
  background-color: #ffffff !important;
}

.lowRisk .rdg-cell {
  background-color: #ffffff !important;
}

.issueReview {
  background-color: #ff2c2c !important;
  color: white !important;
}

.issueReview .rdg-cell {
  background-color: #ff2c2c !important;
  color: white !important;
}

.issueReview .MuiLink-root {
  color: white !important;
}

.MuiTypography-h6 {
  font-size: 0.75rem;
}

.MuiTypography-h5 {
  font-size: 0.8rem;
}

.MuiTypography-h4 {
  font-size: 0.9rem;
}

.MuiFormHelperText-root {
  font-size: 0.7rem;
}

.MuiTypography-root:not(.loginTitle),
.MuiButton-root,
.MuiFilledInput-input {
  font-size: 0.7rem !important;
}

.filter-cell {
  font-size: 0.65rem;
}

h4,
h5 {
  margin-bottom: 0 !important;
}

.MuiFormControl-root {
  width: 100%;
}
*[role='radiogroup'] {
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 10px;
}
.MuiRadio-root {
  padding: 0;
}
.MuiRadio-root .MuiTypography-root {
  width: 35px !important;
}
.MuiFormControlLabel-root {
  margin-right: 0px;
}

*[role*='gridcell'] {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.rdg-cell {
  padding-left: 5px !important;
}
*[role*='columnheader'] {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.MuiFromGroup-root {
  margin-left: 5px;
}
.rdg-cell[aria-colindex='12'] {
  overflow: hidden !important;
}

.rdg-cell[aria-colindex='12'] .MuiFormControl-root {
  height: 99%;
  padding: 1px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdg-cell[aria-colindex='12'] .MuiFilledInput-root {
  height: 90%;
  margin: 0;
  padding: 0;
}

.rdg-cell[aria-colindex='13'] {
  overflow: hidden !important;
}

.rdg-cell[aria-colindex='13'] .MuiFormControl-root {
  height: 99%;
  padding: 1px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdg-cell[aria-colindex='13'] .MuiFilledInput-root {
  height: 90%;
  margin: 0;
  padding: 0;
}

.MuiMenuItem-root {
  font-size: 8pt !important;
}

.MuiTab-root {
  font-size: 0.75rem;
}

*[role='gridcell'] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.MuiInputBase-root {
  padding: 0.2rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
.MuiInputBase-inputMultiline {
  max-height: 25px !important;
  line-height: normal;
}
.MuiButton-root {
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.rdg-cell .MuiFilledInput-root {
  padding: 0;
  height: 25px;
}
.MuiSelect-filled {
  padding: 4px !important;
}

.tableHeight {
  height: calc(100vh - 215px);

  width: 100%;
  overflow: hidden;
}

.MuiGrid-root {
  padding: 6px;
}

.MuiDivider-root {
  padding: 0 !important;
  margin: 6px;
}

[role='columnheader'] {
  position: relative; /* add this to allow absolute positioning of the border */
}

[role='columnheader']::after {
  content: '';
  position: absolute;
  top: 0%; /* position the line at the center of the element */
  right: 0;
  width: 1px;
  height: 100%; /* set the height to 50% of the parent element's height */
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
}

[role='gridcell'] {
  box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
}

.releaseNotes .MuiStepLabel-label {
  font-size: 0.75rem;
  font-weight: 900;
  color: #427bd4;
}

.rowGridItem {
  display: flex;
  flex-direction: column;
  padding: 0.25em;
  height: 42px;
}

.rowGridItem h6 {
  margin: 0;
}

.rowGridColumn {
  padding-right: 1em;
  width: 300px;
}

.rowGrid {
  width: 100%;
  display: flex;
  padding: 0.25em;
}

.row-expander-cell {
  font-size: 0.65rem !important;
}

.rdg-cell {
  outline: 0 !important;
}

.rdg-row > .rdg-cell:first-of-type {
  padding-left: 8px !important;
}
.rdg-cell:focus {
  outline: none;
}

.rdg-row {
  padding: 0 !important;
}

.rdg-header-row {
  padding: 0 !important;
}

.inlineLabel {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
